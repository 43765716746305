<template>
  <div class="container-fluid app-content presentation-mode">
    <Loading v-if="isLoading" />
    <template v-else>
      <CampaignStrap :campaign="campaign" :auto-update-date-time="true" />
      <div class="row my-5">
        <div class="col">
          <LineChart
            :chart-data="chartData"
            :chart-options="chartOptions"
            :height="200"
          ></LineChart>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <EmptyCard v-if="promotions.length === 0" />
          <PromotionCard
            v-for="promotion in promotions"
            :auto-update-date-time="true"
            :promotion="promotion"
            :key="`dashboard-promo-card-${promotion.promotion_id}`"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import hasTimeDate from '../mixins/hasTimeDate';
import hasStatistics from '../mixins/hasStatistics';
import LineChart from '../components/charts/LineChart.vue';
import CampaignStrap from '../components/dashboard/CampaignStrap';
import EmptyCard from '../components/status-cards/EmptyCard';
import Loading from '../components/Loading';
import PromotionCard from '../components/dashboard/PromotionCard';
import * as _ from 'lodash';

export default {
  name: 'Presentation',
  mixins: [hasStatistics, hasTimeDate],
  components: {
    CampaignStrap,
    EmptyCard,
    LineChart,
    Loading,
    PromotionCard,
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [],
      },

      chartOptions: {
        scales: {
          yAxisA: {
            beginAtZero: true,
            ticks: {
              maxTicksLimit: 6,
            },
            grid: { display: true },
          },
          yAxisB: {
            beginAtZero: true,
            ticks: {
              maxTicksLimit: 6,
            },
            grid: { display: false },
            position: 'right',
          },
          x: {
            beginAtZero: true,
            grid: { display: false },
          },
        },
        plugins: { legend: { position: 'bottom' } },
        responsive: true,
        maintainAspectRatio: false,
      },
      dashboardRefreshInterval: null,
      error: null,
      isLoading: true,
      isLoadingBarChart: true,
    };
  },
  computed: {
    campaign() {
      return this.campaigns ? this.campaigns[0] : {};
    },
    campaigns() {
      return this.$store.getters['campaigns/getCurrentCampaigns'];
    },
    currentItem() {
      // console.log('Presentation currentItem()', this.promotions, this.campaign);
      return this.currentIs('promotion') ? this.promotions[0] : this.campaign[0];
    },
    currentItemType() {
      // console.log('Presentation currentItemType()', this.promotions, this.campaign);
      return this.currentIs();
    },
    currentItems() {
      if (this.promotions.length) {
        return this.promotions;
      }

      return [this.campaigns];
    },
    currentTime() {
      return dayjs().format();
    },
    intention() {
      return this.currentItem.intentions ? this.currentItem.intentions[0] : false;
    },
    promotions() {
      return this.$store.getters['promotions/getCurrentPromotions'];
    },
    statsColumnClass() {
      return this.currentIs('promotion') ? 'col-md-4 d-flex flex-column' : 'col-md-6';
    },
  },
  created() {
    // fetch the data when the view is created
    this.fetchData();

    // Fetch a new dashboard every minute
    // Every minute. 1000ms x 60 secs
    const intervalTime = 1000 * 60;
    this.dashboardRefreshInterval = setInterval(this.fetchData, intervalTime);
  },
  unmounted() {
    // Clear the refresh Interval when the component is unmounted
    clearInterval(this.dashboardRefreshInterval);
  },
  methods: {
    /**
     * If `match` variable is passed, check if the CurrentItem is the same type as passed in `match`, and return true
     * or false. If `match` is not passed, then return the type of the CurrentItem.
     *
     * @param {string} [match]
     * @returns {(boolean|string)}
     */
    currentIs(match) {
      let current = false;
      if (this.promotions.length) {
        current = 'promotion';
      } else if (this.campaign) {
        current = 'campaign';
      }

      return match ? current === match : current;
    },
    donors(item) {
      const donors = [];

      item.donations.forEach((donation) => {
        if (!donors.includes(this.fullName(donation))) {
          donors.push(this.fullName(donation));
        }
      });

      return donors;
    },
    fetchData() {
      this.error = null;
      this.isLoading = true;

      const payload = {
        params: {
          with: 'donations;statistics;intentions',
        },
      };

      this.$store
        .dispatch('campaigns/getCurrent', payload)
        .then(() => {
          this.isLoading = false;
          this.fillData();
        })
        .catch((error) => {
          this.error = this.$errorProcessor(error);
        });

      this.$store
        .dispatch('promotions/getCurrent', { with: 'statistics;intentions' })
        .then(() => {
          this.isLoadingPromotions = false;
        })
        .catch((error) => {
          this.errorPromotions = this.$errorProcessor(error);
        });
    },
    fillData() {
      // console.log('Presentation methods fillData() start chartData', this.chartData);

      const amountData = {};
      const countData = {};

      // sort the donations
      const donations = this.campaign.donations.sort((a, b) => {
        return dayjs(a.created_at).diff(b.created_at);
      });

      donations.forEach((donation) => {
        // console.log('CampaignSection methods fillData() forEach donation', donation);
        const donationDate = dayjs(donation.created_at).format('M/D/YY');
        if (donationDate in amountData) {
          amountData[donationDate] += donation.amount;
          countData[donationDate] = countData[donationDate] + 1;
        } else {
          amountData[donationDate] = donation.amount;
          countData[donationDate] = 1;
        }
      });

      // console.log('CampaignSection methods fillData() data', amountData, countData);

      this.chartData = {
        labels: Object.keys(amountData),
        datasets: [
          {
            label: 'Donation Amount ($USD)',
            backgroundColor: '#CCE4F440',
            borderColor: '#0079C7',
            borderWidth: 1,
            data: _.values(amountData),
            yAxisID: 'yAxisA',
          },
          {
            label: 'Donations',
            backgroundColor: '#FBDDD940',
            borderColor: '#EA543F',
            borderWidth: 1,
            data: _.values(countData),
            yAxisID: 'yAxisB',
          },
        ],
      };
    },
    formatDate(date, format) {
      format = format || 'MMMM D';
      return dayjs(date).format(format);
    },
    formatIntentionCounter(intention) {
      // Is it money?
      if (
        intention.type === 'matching' ||
        (intention.type === 'target' && intention.countable === 'amount')
      ) {
        return this.formatAmountStat(intention.counter);
      }
    },
    formatNumber(number) {
      return number ? number.toLocaleString() : '';
    },
    fullName(donor) {
      if (donor.anon) {
        return 'Friend';
      }
      return donor.display_as ?? [donor.first, donor.last].join(' ');
    },
    getIntentionCountableLabel(intention) {
      switch (intention.countable) {
        case 'amount':
          return 'Donation Amount';
        case 'donors':
          return 'Number of Donors';
        case 'donations':
          return 'Number of Donations';
        case 'products':
          return 'Goal';
        case 'pledges ':
          return 'Goal';
      }
    },
    getIntentionTypeLabel(intention) {
      switch (intention.type) {
        case 'giveaway':
          return 'Giveaway';
        case 'matching':
          return 'Match';
        case 'target':
          return 'Goal';
      }
    },
    getLink(item) {
      if (item.promotion_id) {
        return {
          name: 'promotion',
          params: {
            id: item.promotion_id,
          },
        };
      } else {
        return {
          name: 'campaign',
          params: {
            id: item.campaign_id,
          },
        };
      }
    },
    getProgress() {
      // what are we counting
      // Is it money?
      const { intention } = this;
      const { statistics } = this.currentItem;
      if (
        intention.type === 'matching' ||
        (intention.type === 'target' && intention.countable === 'amount')
      ) {
        return statistics.total_sum / intention.counter;
      }

      // Is it a giveaway?
    },
    recentDonors(item) {
      return item.donations.slice(0, 5);
    },
  },
};
</script>

<style scoped lang="scss"></style>
