<template>
  <div id="app" class="authenticated-presentation">
    <!-- Body -->
    <Presentation />
  </div>
</template>

<script>
import Presentation from 'pages/Presentation';

export default {
  name: 'AuthenticatedPresentation',
  components: {
    Presentation,
  },
};
</script>

<style></style>
